.mat-ripple {
  position: relative;
  overflow: hidden;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  pointer-events: none;
  border-radius: 50%;
  transition: opacity, transform cubic-bezier(0, 0, .2, 1);
  position: absolute;
  transform: scale3d(0, 0, 0);
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  outline: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  left: 0;
  overflow: hidden;
}

[dir="rtl"] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.cdk-overlay-container {
  z-index: 1000;
  position: fixed;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  z-index: 1000;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
}

.cdk-overlay-pane {
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  max-width: 100%;
  max-height: 100%;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
}

.cdk-overlay-backdrop {
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  opacity: 0;
  transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: .6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, .32);
}

.cdk-overlay-transparent-backdrop {
  visibility: hidden;
  opacity: 1;
  transition: visibility 1ms linear, opacity 1ms linear;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1000;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
}

.cdk-global-scrollblock {
  width: 100%;
  position: fixed;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  box-sizing: content-box !important;
  height: auto !important;
  padding: 2px 0 !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  box-sizing: content-box !important;
  height: 0 !important;
  padding: 2px 0 !important;
}

@keyframes cdk-text-field-autofill-start {
}

@keyframes cdk-text-field-autofill-end {
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: 0s 1ms cdk-text-field-autofill-start;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: 0s 1ms cdk-text-field-autofill-end;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator:before {
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-focus-indicator:focus:before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator:before {
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-mdc-focus-indicator:focus:before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-h1, .mat-headline-5, .mat-typography .mat-h1, .mat-typography .mat-headline-5, .mat-typography h1 {
  letter-spacing: normal;
  margin: 0 0 16px;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.mat-h2, .mat-headline-6, .mat-typography .mat-h2, .mat-typography .mat-headline-6, .mat-typography h2 {
  letter-spacing: .0125em;
  margin: 0 0 16px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.mat-h3, .mat-subtitle-1, .mat-typography .mat-h3, .mat-typography .mat-subtitle-1, .mat-typography h3 {
  letter-spacing: .009375em;
  margin: 0 0 16px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.mat-h4, .mat-body-1, .mat-typography .mat-h4, .mat-typography .mat-body-1, .mat-typography h4 {
  letter-spacing: .03125em;
  margin: 0 0 16px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.mat-h5, .mat-typography .mat-h5, .mat-typography h5 {
  margin: 0 0 12px;
  font: 400 11.62px / 20px Roboto, sans-serif;
}

.mat-h6, .mat-typography .mat-h6, .mat-typography h6 {
  margin: 0 0 12px;
  font: 400 9.38px / 20px Roboto, sans-serif;
}

.mat-body-strong, .mat-subtitle-2, .mat-typography .mat-body-strong, .mat-typography .mat-subtitle-2 {
  letter-spacing: .00714286em;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
  letter-spacing: .0178571em;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.mat-body p, .mat-body-2 p, .mat-typography .mat-body p, .mat-typography .mat-body-2 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption, .mat-typography .mat-small, .mat-typography .mat-caption {
  letter-spacing: .0333333em;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.mat-headline-1, .mat-typography .mat-headline-1 {
  letter-spacing: -.015625em;
  margin: 0 0 56px;
  font-family: Roboto, sans-serif;
  font-size: 96px;
  font-weight: 300;
  line-height: 96px;
}

.mat-headline-2, .mat-typography .mat-headline-2 {
  letter-spacing: -.00833333em;
  margin: 0 0 64px;
  font-family: Roboto, sans-serif;
  font-size: 60px;
  font-weight: 300;
  line-height: 60px;
}

.mat-headline-3, .mat-typography .mat-headline-3 {
  letter-spacing: normal;
  margin: 0 0 64px;
  font-family: Roboto, sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 50px;
}

.mat-headline-4, .mat-typography .mat-headline-4 {
  letter-spacing: .00735294em;
  margin: 0 0 64px;
  font-family: Roboto, sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, .1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, .87));
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, .04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #0d72ed);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, white);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, .87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, .54);
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: #0d72ed;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #0d72ed;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full:after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full:after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: #fff;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #fff;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full:after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full:after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after, .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: #fff;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #fff;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full:after, .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full:after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after, .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: #e8163c;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e8163c;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full:after, .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full:after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after, .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: #b0b0b0;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  color: rgba(0, 0, 0, .87);
  background-color: #fafafa;
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0 0 rgba(0, 0, 0, .2), 0 0 rgba(0, 0, 0, .14), 0 0 rgba(0, 0, 0, .12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px rgba(0, 0, 0, .14), 0 1px 3px rgba(0, 0, 0, .12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px rgba(0, 0, 0, .14), 0 1px 8px rgba(0, 0, 0, .12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px rgba(0, 0, 0, .14), 0 1px 10px rgba(0, 0, 0, .12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px rgba(0, 0, 0, .14), 0 1px 14px rgba(0, 0, 0, .12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px rgba(0, 0, 0, .14), 0 1px 18px rgba(0, 0, 0, .12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, .2), 0 7px 10px 1px rgba(0, 0, 0, .14), 0 2px 16px 1px rgba(0, 0, 0, .12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, .2), 0 9px 12px 1px rgba(0, 0, 0, .14), 0 3px 16px 2px rgba(0, 0, 0, .12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, .2), 0 10px 14px 1px rgba(0, 0, 0, .14), 0 4px 18px 3px rgba(0, 0, 0, .12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0 6px 7px -4px rgba(0, 0, 0, .2), 0 11px 15px 1px rgba(0, 0, 0, .14), 0 4px 20px 3px rgba(0, 0, 0, .12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0 7px 9px -4px rgba(0, 0, 0, .2), 0 14px 21px 2px rgba(0, 0, 0, .14), 0 5px 26px 4px rgba(0, 0, 0, .12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0 8px 9px -5px rgba(0, 0, 0, .2), 0 15px 22px 2px rgba(0, 0, 0, .14), 0 6px 28px 5px rgba(0, 0, 0, .12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, .2), 0 17px 26px 2px rgba(0, 0, 0, .14), 0 6px 32px 5px rgba(0, 0, 0, .12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0 9px 11px -5px rgba(0, 0, 0, .2), 0 18px 28px 2px rgba(0, 0, 0, .14), 0 7px 34px 6px rgba(0, 0, 0, .12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0 9px 12px -6px rgba(0, 0, 0, .2), 0 19px 29px 2px rgba(0, 0, 0, .14), 0 7px 36px 6px rgba(0, 0, 0, .12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 20px 31px 3px rgba(0, 0, 0, .14), 0 8px 38px 7px rgba(0, 0, 0, .12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, .2), 0 21px 33px 3px rgba(0, 0, 0, .14), 0 8px 40px 7px rgba(0, 0, 0, .12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, .2), 0 22px 35px 3px rgba(0, 0, 0, .14), 0 8px 42px 7px rgba(0, 0, 0, .12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0 11px 14px -7px rgba(0, 0, 0, .2), 0 23px 36px 3px rgba(0, 0, 0, .14), 0 9px 44px 8px rgba(0, 0, 0, .12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, .03125em);
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px rgba(0, 0, 0, .14), 0 1px 3px rgba(0, 0, 0, .12);
}

.mat-mdc-card-outlined {
  --mdc-outlined-card-outline-color: #e0e0e0;
  box-shadow: 0 0 rgba(0, 0, 0, .2), 0 0 rgba(0, 0, 0, .14), 0 0 rgba(0, 0, 0, .12);
}

.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, .54);
}

.mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, .0125em);
  -webkit-text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, .00714286em);
  -webkit-text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0d72ed;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(13, 114, 237, 0.25)'/%3E%3C/svg%3E");
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(13, 114, 237, .25);
}

.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: white;
}

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 255, 255, 0.25)'/%3E%3C/svg%3E");
}

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 255, 255, .25);
}

.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #e8163c;
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(232, 22, 60, 0.25)'/%3E%3C/svg%3E");
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(232, 22, 60, .25);
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: .0333333em;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, .6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, .87);
}

@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, .6);
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, .6);
  }
}

.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #0d72ed);
}

.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text, .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter, .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, .6);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading, .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, .54);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix, .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, .6);
}

.mdc-text-field--filled .mdc-text-field__ripple:before, .mdc-text-field--filled .mdc-text-field__ripple:after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, .87));
}

.mdc-text-field--filled:hover .mdc-text-field__ripple:before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple:before {
  opacity: var(--mdc-ripple-hover-opacity, .04);
}

.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple:before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple:before {
  opacity: var(--mdc-ripple-focus-opacity, .12);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #f5f5f5;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple:before {
  border-bottom-color: rgba(0, 0, 0, .42);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple:before {
  border-bottom-color: rgba(0, 0, 0, .87);
}

.mdc-text-field--filled .mdc-line-ripple:after {
  border-bottom-color: var(--mdc-theme-primary, #0d72ed);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, .38);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, .87);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #0d72ed);
}

.mdc-text-field--outlined .mdc-text-field__ripple:before, .mdc-text-field--outlined .mdc-text-field__ripple:after {
  background-color: var(--mdc-ripple-color, transparent);
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(13, 114, 237, .87);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple:before, .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple:after {
  border-bottom-color: var(--mdc-theme-error, #e8163c);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e8163c);
}

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e8163c);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e8163c);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple:before {
  border-bottom-color: var(--mdc-theme-error, #e8163c);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing, .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch, .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e8163c);
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, .38);
}

@media all {
  .mdc-text-field--disabled .mdc-text-field__input::-ms-input-placeholder {
    color: rgba(0, 0, 0, .38);
  }

  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, .38);
  }

  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, .38);
  }
}

.mdc-text-field--disabled .mdc-floating-label, .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text, .mdc-text-field--disabled .mdc-text-field-character-counter, .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, .38);
}

.mdc-text-field--disabled .mdc-text-field__icon--leading, .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, .3);
}

.mdc-text-field--disabled .mdc-text-field__affix--prefix, .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, .38);
}

.mdc-text-field--disabled .mdc-line-ripple:before {
  border-bottom-color: rgba(0, 0, 0, .06);
}

.mdc-text-field--disabled .mdc-notched-outline__leading, .mdc-text-field--disabled .mdc-notched-outline__notch, .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, .06);
}

@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::-ms-input-placeholder {
    color: GrayText;
  }

  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }

  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }

  .mdc-text-field--disabled .mdc-floating-label, .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text, .mdc-text-field--disabled .mdc-text-field-character-counter, .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter, .mdc-text-field--disabled .mdc-text-field__icon--leading, .mdc-text-field--disabled .mdc-text-field__icon--trailing, .mdc-text-field--disabled .mdc-text-field__affix--prefix, .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }

  .mdc-text-field--disabled .mdc-line-ripple:before {
    border-bottom-color: GrayText;
  }

  .mdc-text-field--disabled .mdc-notched-outline__leading, .mdc-text-field--disabled .mdc-notched-outline__notch, .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, .87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: .04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: .12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix:after {
  color: rgba(0, 0, 0, .54);
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix:after {
  color: rgba(13, 114, 237, .87);
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix:after {
  color: rgba(255, 255, 255, .87);
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix:after {
  color: rgba(232, 22, 60, .87);
}

.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix:after {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, white);
}

.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple:after {
  border-bottom-color: var(--mdc-theme-secondary, white);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, .87);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple:before, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple:after {
  border-bottom-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple:before {
  border-bottom-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, white);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple:after {
  border-bottom-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(232, 22, 60, .87);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple:before, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple:after {
  border-bottom-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple:before {
  border-bottom-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing, .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #e8163c);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid rgba(0, 0, 0, 0);
}

[dir="rtl"] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid rgba(0, 0, 0, 0);
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-34.75px) scale(var(--mat-mdc-form-field-floating-label-scale, .75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input, .mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, .009375em);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, .009375em);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, .0333333em);
  -webkit-text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field, .mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, .03125em);
  -webkit-text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(16px * var(--mat-mdc-form-field-floating-label-scale, .75));
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 16px;
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, .87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, .6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, .54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(13, 114, 237, .87);
}

.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, .87);
}

.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow, .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(232, 22, 60, .87);
}

.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, .009375em);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  line-height: 24px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, .03125em);
  -webkit-text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, .009375em);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  line-height: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, .12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, .87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, .6);
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: .0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: .03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}

.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #0d72ed;
  --mdc-chip-elevated-disabled-container-color: #0d72ed;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: white;
  --mdc-chip-elevated-disabled-container-color: white;
  --mdc-chip-label-text-color: black;
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
}

.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #e8163c;
  --mdc-chip-elevated-disabled-container-color: #e8163c;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: #000;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, .0178571em);
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, .87));
}

.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #0b6aeb;
  --mdc-switch-selected-handle-color: #0b6aeb;
  --mdc-switch-selected-hover-state-layer-color: #0b6aeb;
  --mdc-switch-selected-pressed-state-layer-color: #0b6aeb;
  --mdc-switch-selected-focus-handle-color: #0342e0;
  --mdc-switch-selected-hover-handle-color: #0342e0;
  --mdc-switch-selected-pressed-handle-color: #0342e0;
  --mdc-switch-selected-focus-track-color: #569cf2;
  --mdc-switch-selected-hover-track-color: #569cf2;
  --mdc-switch-selected-pressed-track-color: #569cf2;
  --mdc-switch-selected-track-color: #569cf2;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #e3e2e7;
  --mdc-switch-selected-handle-color: #e3e2e7;
  --mdc-switch-selected-hover-state-layer-color: #e3e2e7;
  --mdc-switch-selected-pressed-state-layer-color: #e3e2e7;
  --mdc-switch-selected-focus-handle-color: #d5d3db;
  --mdc-switch-selected-hover-handle-color: #d5d3db;
  --mdc-switch-selected-pressed-handle-color: #d5d3db;
  --mdc-switch-selected-focus-track-color: #eeedf0;
  --mdc-switch-selected-hover-track-color: #eeedf0;
  --mdc-switch-selected-pressed-track-color: #eeedf0;
  --mdc-switch-selected-track-color: #eeedf0;
}

.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e51336;
  --mdc-switch-selected-handle-color: #e51336;
  --mdc-switch-selected-hover-state-layer-color: #e51336;
  --mdc-switch-selected-pressed-state-layer-color: #e51336;
  --mdc-switch-selected-focus-handle-color: #d8061a;
  --mdc-switch-selected-hover-handle-color: #d8061a;
  --mdc-switch-selected-pressed-handle-color: #d8061a;
  --mdc-switch-selected-focus-track-color: #ef5c77;
  --mdc-switch-selected-hover-track-color: #ef5c77;
  --mdc-switch-selected-pressed-track-color: #ef5c77;
  --mdc-switch-selected-track-color: #ef5c77;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, .87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-selected-focus-icon-color: #0d72ed;
  --mdc-radio-selected-hover-icon-color: #0d72ed;
  --mdc-radio-selected-icon-color: #0d72ed;
  --mdc-radio-selected-pressed-icon-color: #0d72ed;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #0d72ed;
}

.mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-selected-focus-icon-color: white;
  --mdc-radio-selected-hover-icon-color: white;
  --mdc-radio-selected-icon-color: white;
  --mdc-radio-selected-pressed-icon-color: white;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: white;
}

.mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-selected-focus-icon-color: #e8163c;
  --mdc-radio-selected-hover-icon-color: #e8163c;
  --mdc-radio-selected-icon-color: #e8163c;
  --mdc-radio-selected-pressed-icon-color: #e8163c;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e8163c;
}

.mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-radio-button .mdc-radio {
  padding: 10px;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background:before {
  width: 40px;
  height: 40px;
  top: -10px;
  left: -10px;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  right: 0;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: .6;
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #0d72ed;
  --mdc-slider-focus-handle-color: #0d72ed;
  --mdc-slider-hover-handle-color: #0d72ed;
  --mdc-slider-active-track-color: #0d72ed;
  --mdc-slider-inactive-track-color: #0d72ed;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #0d72ed;
  --mat-mdc-slider-ripple-color: #0d72ed;
  --mat-mdc-slider-hover-ripple-color: rgba(13, 114, 237, .05);
  --mat-mdc-slider-focus-ripple-color: rgba(13, 114, 237, .2);
}

.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: white;
  --mdc-slider-focus-handle-color: white;
  --mdc-slider-hover-handle-color: white;
  --mdc-slider-active-track-color: white;
  --mdc-slider-inactive-track-color: white;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: white;
  --mat-mdc-slider-ripple-color: white;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 255, 255, .05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 255, 255, .2);
}

.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #e8163c;
  --mdc-slider-focus-handle-color: #e8163c;
  --mdc-slider-hover-handle-color: #e8163c;
  --mdc-slider-active-track-color: #e8163c;
  --mdc-slider-inactive-track-color: #e8163c;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #e8163c;
  --mat-mdc-slider-ripple-color: #e8163c;
  --mat-mdc-slider-hover-ripple-color: rgba(232, 22, 60, .05);
  --mat-mdc-slider-focus-ripple-color: rgba(232, 22, 60, .2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: .00714286em;
  --mdc-slider-label-label-text-weight: 500;
}

.mdc-menu-surface {
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
}

.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, .87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, .54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, .38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, .38));
  background-color: rgba(0, 0, 0, 0);
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, .38));
}

.mdc-list-item--disabled .mdc-list-item__start, .mdc-list-item--disabled .mdc-list-item__content, .mdc-list-item--disabled .mdc-list-item__end {
  opacity: .38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text, .mdc-list-item--disabled .mdc-list-item__secondary-text, .mdc-list-item--disabled .mdc-list-item__overline-text, .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end, .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--activated .mdc-list-item__primary-text, .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start, .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #0d72ed);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, .87));
}

.mdc-list-divider:after {
  border-bottom-color: #fff;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, .12);
}

.mat-mdc-menu-item[disabled], .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon, .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, .38));
}

.mat-mdc-menu-item .mat-icon-no-color, .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, .87));
}

.mat-mdc-menu-item:hover:not([disabled]), .mat-mdc-menu-item.cdk-program-focused:not([disabled]), .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]), .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, .04);
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, .009375em);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  line-height: 24px;
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body1-font-size, 16px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, .03125em);
  -webkit-text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, .87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, .54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, .87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, .38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, .87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: .04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: .12;
}

.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0d72ed;
  --mdc-checkbox-selected-hover-icon-color: #0d72ed;
  --mdc-checkbox-selected-icon-color: #0d72ed;
  --mdc-checkbox-selected-pressed-icon-color: #0d72ed;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-selected-focus-icon-color: #0d72ed;
  --mdc-radio-selected-hover-icon-color: #0d72ed;
  --mdc-radio-selected-icon-color: #0d72ed;
  --mdc-radio-selected-pressed-icon-color: #0d72ed;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #0d72ed;
}

.mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: white;
  --mdc-checkbox-selected-hover-icon-color: white;
  --mdc-checkbox-selected-icon-color: white;
  --mdc-checkbox-selected-pressed-icon-color: white;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-selected-focus-icon-color: white;
  --mdc-radio-selected-hover-icon-color: white;
  --mdc-radio-selected-icon-color: white;
  --mdc-radio-selected-pressed-icon-color: white;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: white;
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e8163c;
  --mdc-checkbox-selected-hover-icon-color: #e8163c;
  --mdc-checkbox-selected-icon-color: #e8163c;
  --mdc-checkbox-selected-pressed-icon-color: #e8163c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
  --mdc-radio-selected-focus-icon-color: #e8163c;
  --mdc-radio-selected-hover-icon-color: #e8163c;
  --mdc-radio-selected-icon-color: #e8163c;
  --mdc-radio-selected-pressed-icon-color: #e8163c;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #e8163c;
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #0d72ed;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start, .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content, .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: .03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: .0178571em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: .0333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  letter-spacing: .009375em;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.mat-mdc-paginator {
  color: rgba(0, 0, 0, .87);
  background: #fff;
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, .54);
}

.mat-mdc-paginator-decrement, .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, .54);
  border-right: 2px solid rgba(0, 0, 0, .54);
}

.mat-mdc-paginator-first, .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, .54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement, .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment, .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first, .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, .12);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, .12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-26.75px) scale(var(--mat-mdc-form-field-floating-label-scale, .75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix, .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix, .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, .0333333em);
  -webkit-text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  background-color: rgba(0, 0, 0, 0);
}

.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, .6);
}

.mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple:before, .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple:before, .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, .38);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #0d72ed;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #0d72ed);
}

.mdc-tab__ripple:before, .mat-mdc-tab .mat-ripple-element, .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-link .mat-ripple-element {
  background-color: #0d72ed;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #fff;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, white);
}

.mat-mdc-tab-group.mat-accent .mdc-tab__ripple:before, .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple:before, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #fff;
}

.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #e8163c;
}

.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #e8163c);
}

.mat-mdc-tab-group.mat-warn .mdc-tab__ripple:before, .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple:before, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #e8163c;
}

.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #0d72ed;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}

.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: white;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}

.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #e8163c;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, .0892857em);
  -webkit-text-decoration: var(--mdc-typography-button-text-decoration, none);
  -webkit-text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, .87));
}

.mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, .1);
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #0d72ed;
  --mdc-checkbox-selected-hover-icon-color: #0d72ed;
  --mdc-checkbox-selected-icon-color: #0d72ed;
  --mdc-checkbox-selected-pressed-icon-color: #0d72ed;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(13, 114, 237, .1);
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #0d72ed;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: white;
  --mdc-checkbox-selected-hover-icon-color: white;
  --mdc-checkbox-selected-icon-color: white;
  --mdc-checkbox-selected-pressed-icon-color: white;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(255, 255, 255, .1);
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #fff;
}

.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e8163c;
  --mdc-checkbox-selected-hover-icon-color: #e8163c;
  --mdc-checkbox-selected-icon-color: #e8163c;
  --mdc-checkbox-selected-pressed-icon-color: #e8163c;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, .38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, .54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, .54);
}

.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(232, 22, 60, .1);
}

.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #e8163c;
}

.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, .38);
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px)  - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px)  - 40px) / 2);
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px)  - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px)  - 18px) / 2);
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

@media (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, .0178571em);
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0d72ed;
}

.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: white;
}

.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e8163c;
}

.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, .38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, .38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0d72ed;
  --mdc-filled-button-label-text-color: #fff;
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
}

.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e8163c;
  --mdc-filled-button-label-text-color: #fff;
}

.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, .12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, .38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, .12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, .38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0d72ed;
  --mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
}

.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e8163c;
  --mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, .12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, .38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, .12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, .38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, .12);
}

.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #0d72ed;
}

.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: white;
}

.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e8163c;
}

.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, .38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, .38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, .12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, .12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, .1);
}

.mat-mdc-button:hover .mat-mdc-button-persistent-ripple:before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple:before {
  opacity: .04;
}

.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-button:active .mat-mdc-button-persistent-ripple:before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple:before {
  opacity: .12;
}

.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0d72ed;
  --mat-mdc-button-ripple-color: rgba(13, 114, 237, .1);
}

.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: white;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, .1);
}

.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e8163c;
  --mat-mdc-button-ripple-color: rgba(232, 22, 60, .1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, .1);
}

.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple:before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple:before {
  opacity: .04;
}

.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple:before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple:before {
  opacity: .12;
}

.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, .1);
}

.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, .1);
}

.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, .1);
}

.mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, .0892857em);
  -webkit-text-decoration: var(--mdc-typography-button-text-decoration, none);
  -webkit-text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, .1);
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple:before {
  opacity: .04;
}

.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple:before {
  opacity: .12;
}

.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #0d72ed;
  --mat-mdc-button-ripple-color: rgba(13, 114, 237, .1);
}

.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: white;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, .1);
}

.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #e8163c;
  --mat-mdc-button-ripple-color: rgba(232, 22, 60, .1);
}

.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0d72ed;
}

.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: white;
}

.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e8163c;
}

.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, .38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, .38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}

.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-width: 48px;
  max-height: 48px;
}

.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin: 4px;
}

.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-width: 40px;
  max-height: 40px;
}

.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, .1);
}

.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple:before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple:before {
  opacity: .04;
}

.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple:before, .mat-mdc-fab:active .mat-mdc-button-persistent-ripple:before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple:before {
  opacity: .12;
}

.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, .1);
}

.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, .1);
}

.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, .1);
}

.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}

.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #0d72ed;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}

.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #e8163c;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, .12);
  --mdc-fab-icon-color: rgba(0, 0, 0, .38);
  --mat-mdc-fab-color: rgba(0, 0, 0, .38);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 36px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, .0892857em);
  -webkit-text-decoration: var(--mdc-typography-button-text-decoration, none);
  -webkit-text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: white;
  --mdc-snackbar-container-color: #333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, .87);
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, .12);
}

.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(13, 114, 237, .04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading, .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch, .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, .12);
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, .12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, .12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, .04);
}

.mdc-data-table__header-cell, .mdc-data-table__pagination-total, .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__cell {
  color: rgba(0, 0, 0, .87);
}

.mat-mdc-table {
  background: #fff;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}

.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}

.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__content, .mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, .0178571em);
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 22px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, .00714286em);
  -webkit-text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  -webkit-text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #0d72ed;
}

.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: white;
}

.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #e8163c;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  border-radius: 50%;
  transition: transform .2s ease-in-out;
  display: inline-block;
  position: absolute;
  overflow: hidden;
  transform: scale(.6);
}

.ng-animate-disabled .mat-badge-content, .mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}

[dir="rtl"] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}

[dir="rtl"] .mat-badge-small.mat-badge-after .mat-badge-content {
  left: -16px;
  right: auto;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}

[dir="rtl"] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

[dir="rtl"] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  left: -8px;
  right: auto;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-after .mat-badge-content {
  left: -22px;
  right: auto;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  left: -11px;
  right: auto;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}

[dir="rtl"] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}

[dir="rtl"] .mat-badge-large.mat-badge-after .mat-badge-content {
  left: -28px;
  right: auto;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}

[dir="rtl"] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}

[dir="rtl"] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  left: -14px;
  right: auto;
}

.mat-badge-content {
  color: #fff;
  background: #0d72ed;
}

.cdk-high-contrast-active .mat-badge-content {
  border-radius: 0;
  outline: 1px solid;
}

.mat-badge-accent .mat-badge-content {
  color: #000;
  background: #fff;
}

.mat-badge-warn .mat-badge-content {
  color: #fff;
  background: #e8163c;
}

.mat-badge-disabled .mat-badge-content {
  color: rgba(0, 0, 0, .38);
  background: #b9b9b9;
}

.mat-badge-content {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  color: rgba(0, 0, 0, .87);
  letter-spacing: .0178571em;
  background: #fff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
}

.mat-button-toggle-standalone:not([class*="mat-elevation-z"]), .mat-button-toggle-group:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*="mat-elevation-z"]), .mat-button-toggle-group-appearance-standard:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, .38);
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, .12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, .87);
  background: #fff;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: #000;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: 1px solid #e0e0e0;
}

[dir="rtl"] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: 1px solid #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-top: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
}

.mat-button-toggle-checked {
  color: rgba(0, 0, 0, .54);
  background-color: #e0e0e0;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, .87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, .26);
  background-color: #eee;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #fff;
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
  border: 1px solid #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: Roboto, sans-serif;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, .54);
}

.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, .54);
}

.mat-calendar-table-header-divider:after {
  background: rgba(0, 0, 0, .12);
}

.mat-calendar-table-header, .mat-calendar-body-label {
  color: rgba(0, 0, 0, .54);
}

.mat-calendar-body-cell-content, .mat-date-range-input-separator {
  color: rgba(0, 0, 0, .87);
  border-color: rgba(0, 0, 0, 0);
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, .38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, .24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, .38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, .18);
}

.mat-calendar-body-in-range:before {
  background: rgba(13, 114, 237, .2);
}

.mat-calendar-body-comparison-identical, .mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, .2);
}

.mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to right, rgba(13, 114, 237, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

[dir="rtl"] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right, rgba(13, 114, 237, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to left, rgba(13, 114, 237, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

[dir="rtl"] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left, rgba(13, 114, 237, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical, .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected, .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  color: #fff;
  background-color: #0d72ed;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(13, 114, 237, .4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(13, 114, 237, .3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(13, 114, 237, .3);
  }
}

.mat-datepicker-content {
  color: rgba(0, 0, 0, .87);
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px rgba(0, 0, 0, .14), 0 1px 10px rgba(0, 0, 0, .12);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range:before {
  background: rgba(255, 255, 255, .2);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, .2);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to right, rgba(255, 255, 255, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-datepicker-content.mat-accent [dir="rtl"] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right, rgba(255, 255, 255, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to left, rgba(255, 255, 255, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-datepicker-content.mat-accent [dir="rtl"] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left, rgba(255, 255, 255, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected, .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  color: #000;
  background-color: #fff;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 255, 255, .4);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #000;
}

.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, .3);
}

@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 255, 255, .3);
  }
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range:before {
  background: rgba(232, 22, 60, .2);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range:before {
  background: rgba(249, 171, 0, .2);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to right, rgba(232, 22, 60, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-datepicker-content.mat-warn [dir="rtl"] .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to right, rgba(232, 22, 60, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end:before {
  background: linear-gradient(to left, rgba(232, 22, 60, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-datepicker-content.mat-warn [dir="rtl"] .mat-calendar-body-comparison-bridge-start:before {
  background: linear-gradient(to left, rgba(232, 22, 60, .2) 50%, rgba(249, 171, 0, .2) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical, .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range:after {
  background: #a8dab5;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected, .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  color: #fff;
  background-color: #e8163c;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(232, 22, 60, .4);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(232, 22, 60, .3);
}

@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(232, 22, 60, .3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
}

.mat-datepicker-toggle-active {
  color: #0d72ed;
}

.mat-datepicker-toggle-active.mat-accent {
  color: #fff;
}

.mat-datepicker-toggle-active.mat-warn {
  color: #e8163c;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, .38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-width: 40px;
  max-height: 40px;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin: 0;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-width: 40px;
  max-height: 40px;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: Roboto, sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label, .mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, .12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, .12);
}

.mat-expansion-panel {
  color: rgba(0, 0, 0, .87);
  background: #fff;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, .12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background: rgba(0, 0, 0, .04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"]) .mat-expansion-panel-header:hover {
    background: #fff;
  }
}

.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, .87);
}

.mat-expansion-panel-header-description, .mat-expansion-indicator:after {
  color: rgba(0, 0, 0, .54);
}

.mat-expansion-panel-header[aria-disabled="true"] {
  color: rgba(0, 0, 0, .26);
}

.mat-expansion-panel-header[aria-disabled="true"] .mat-expansion-panel-header-title, .mat-expansion-panel-header[aria-disabled="true"] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}

.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  letter-spacing: .0178571em;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.mat-grid-tile-header, .mat-grid-tile-footer {
  font-size: 14px;
}

.mat-grid-tile-header .mat-line, .mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
}

.mat-grid-tile-header .mat-line:nth-child(n+2), .mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: #0d72ed;
}

.mat-icon.mat-accent {
  color: #fff;
}

.mat-icon.mat-warn {
  color: #e8163c;
}

.mat-drawer-container {
  color: rgba(0, 0, 0, .87);
  background-color: #fafafa;
}

.mat-drawer {
  color: rgba(0, 0, 0, .87);
  background-color: #fff;
}

.mat-drawer.mat-drawer-push {
  background-color: #fff;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
}

.mat-drawer-side {
  border-right: 1px solid rgba(0, 0, 0, .12);
}

.mat-drawer-side.mat-drawer-end {
  border-left: 1px solid rgba(0, 0, 0, .12);
  border-right: none;
}

[dir="rtl"] .mat-drawer-side {
  border-left: 1px solid rgba(0, 0, 0, .12);
  border-right: none;
}

[dir="rtl"] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: 1px solid rgba(0, 0, 0, .12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, .6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled="false"] {
  background-color: rgba(0, 0, 0, .04);
}

.mat-step-header:hover[aria-disabled="true"] {
  cursor: default;
}

@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}

.mat-step-header .mat-step-label, .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, .54);
}

.mat-step-header .mat-step-icon {
  color: #fff;
  background-color: rgba(0, 0, 0, .54);
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  color: #fff;
  background-color: #0d72ed;
}

.mat-step-header.mat-accent .mat-step-icon {
  color: #000;
}

.mat-step-header.mat-accent .mat-step-icon-selected, .mat-step-header.mat-accent .mat-step-icon-state-done, .mat-step-header.mat-accent .mat-step-icon-state-edit {
  color: #000;
  background-color: #fff;
}

.mat-step-header.mat-warn .mat-step-icon {
  color: #fff;
}

.mat-step-header.mat-warn .mat-step-icon-selected, .mat-step-header.mat-warn .mat-step-icon-state-done, .mat-step-header.mat-warn .mat-step-icon-state-edit {
  color: #fff;
  background-color: #e8163c;
}

.mat-step-header .mat-step-icon-state-error {
  color: #e8163c;
  background-color: rgba(0, 0, 0, 0);
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, .87);
}

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #e8163c;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: #fff;
}

.mat-stepper-vertical-line:before {
  border-left-color: rgba(0, 0, 0, .12);
}

.mat-horizontal-stepper-header:before, .mat-horizontal-stepper-header:after, .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, .12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
  padding: 24px;
}

.mat-stepper-vertical-line:before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:before, .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: 400;
}

.mat-step-label-error {
  font-size: 16px;
}

.mat-step-label-selected {
  font-size: 16px;
  font-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  color: rgba(0, 0, 0, .87);
  background: #f5f5f5;
}

.mat-toolbar.mat-primary {
  color: #fff;
  background: #0d72ed;
}

.mat-toolbar.mat-accent {
  color: #000;
  background: #fff;
}

.mat-toolbar.mat-warn {
  color: #fff;
  background: #e8163c;
}

.mat-toolbar .mat-form-field-underline, .mat-toolbar .mat-form-field-ripple, .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}

.mat-toolbar .mat-form-field-label, .mat-toolbar .mat-focused .mat-form-field-label, .mat-toolbar .mat-select-value, .mat-toolbar .mat-select-arrow, .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}

.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}

.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
  letter-spacing: .0125em;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.mat-tree {
  background: #fff;
}

.mat-tree-node, .mat-nested-tree-node {
  color: rgba(0, 0, 0, .87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Roboto, sans-serif;
}

.mat-tree-node, .mat-nested-tree-node {
  font-size: 14px;
  font-weight: 400;
}

app-uitoolkit *, app-previewkit *, #videosdk-ui-toolkit *, mat-dialog-content.videosdk-ui-toolkit-cdk-overlay-container * {
  box-sizing: border-box;
}

mat-dialog-content.videosdk-ui-toolkit-cdk-overlay-container {
  max-width: 500px;
}

.mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: visible;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

mat-tab-body {
  margin-top: 20px;
}

/*# sourceMappingURL=index.c3bc725e.css.map */
